import React from "react";

import {
  // Customizable Area Start
  DialogContentText, // Customizable Area End
  DialogTitle,
} from "@mui/material";

// Customizable Area Start
import { View, StyleSheet, Text, TouchableOpacity, Platform, ScrollView } from "react-native";
import { Input, Button, Typography } from '@builder/component-library';
import Select from "react-select";
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import { Logo, Checked } from './assets'
import {
  Theme,
} from "@mui/material/styles";
// Customizable Area End

import FreeTrialController, { Props, configJSON } from "./FreeTrialController";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class FreeTrial extends FreeTrialController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.screen}>
        <ScrollView>
          <View style={styles.main}>
            <View style={styles.content}>
              <View style={styles.subscriptionInformation}>
                <View style={styles.subscriptionInformationHeader}>
                  <TouchableOpacity testID="btnGoBack" onPress={() => this.props.navigation.goBack()}>
                    <ArrowLeftIcon sx={styles.goBackIcon} />
                  </TouchableOpacity>
                  <Typography variant="xl" style={styles.subscriptionInformationHeaderTitle}>Subscription information</Typography>
                </View>

                <View style={styles.subscriptionInformationContent}>
                  <View style={styles.subscriptionInformationContentHeader}>
                    <img style={styles.logo} src={Logo} />
                    <View>
                      <Typography variant="xl" style={styles.planName}>Individual User Plan</Typography>
                      <Typography variant="xs" style={styles.subTitle}>Perfect plan for Individuals</Typography>
                    </View>
                  </View>

                  <View style={styles.benefits}>
                    <View style={styles.benefit}>
                        <img style={styles.checked} src={Checked} />
                        <Text style={styles.benefitText}>  
                          <Text style={styles.boldText}>Full access</Text> to Hometown Maps!
                        </Text>
                    </View>
                  </View>

                  <Typography variant="xs" style={styles.planAndPriceTitle}>PLANS & PRICING</Typography>

                  <Typography variant="xl" style={styles.freeWeek}>Free 1 week trail</Typography>

                  <Text  style={styles.pricePeriodText}>
                    <Text style={styles.priceText}>$10.99</Text> / year
                  </Text>
                </View>
              </View>


              <View style={styles.billedContainer}>
                <Typography variant="xs" style={styles.billedToText}>BILLED TO</Typography>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Name</Typography>
                  <Input 
                    testID="txtInputName"
                    style={styles.input}
                    value={this.state.name}
                    {...this.txtInputNameProps} 
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Card number</Typography>
                  <Input 
                    testID="txtInputCardNumber"
                    style={styles.input}
                    value={this.state.cardNumber}
                    maxLength={19}
                    {...this.txtInputCardNumberProps} 
                  />
                </View>

                <View style={styles.fieldContainer}>
                  <View style={styles.halfField}>
                    <Typography variant="sm" style={styles.label}>Expiration Date</Typography>
                    <Input 
                      testID="txtInputExpirationDate"
                      style={styles.input}
                      maxLength={5}
                      value={this.state.expirationDate}
                      {...this.txtInputExpirationDateProps} 
                    />
                  </View>

                  <View style={styles.halfField}>
                    <Typography variant="sm" style={styles.label}>CVV</Typography>
                    <Input 
                      testID="txtInputCvv"
                      style={styles.input}
                      value={this.state.cvv}
                      maxLength={3}
                      {...this.txtInputCvvProps} 
                    />
                  </View>
                </View>

                <View style={[styles.field, { zIndex: 99 }]}>
                  <Typography variant="sm" style={styles.label}>Country</Typography>

                  <Select 
                    style={{ fontFamily: "Inter" }}
                    options={configJSON.countries}
                    styles={{
                      control: (baseStyle) => ({
                        ...baseStyle,
                        borderRadius: 10,
                        height: 44,
                      }),
                      container: (baseStyle) => ({
                        ...baseStyle,
                        fontFamily: "Inter",
                      }),    
                    }}
                    testID="txtSelectCountry"
                    placeholder=""
                    value={this.state.country}
                    {...this.selectInputCountryProps}
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Zip code</Typography>
                  <Input 
                    testID="txtInputZipCode"
                    style={styles.input}
                    value={this.state.zipCode}
                    {...this.txtInputZipCodeProps} 
                  />
                </View>

                <Button 
                  testID="btnProceedPay"
                  textStyle={styles.buttonText}
                  style={styles.button}
                  text="Proceed to pay"
                  {...this.btnProceedPay}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screen: {
    width: "100%", 
    height: "100vh", 
    backgroundColor: "#9BCE7B"
  },
  main: {
    flex: 1, 
    display: "flex", 
    marginHorizontal: "auto", 
    marginTop: 170,
    width: "100%", 
    height: "100%",
    maxWidth: 822
  },
  content: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  subscriptionInformation: {
    width: "100%",
    maxWidth: 375
  },
  subscriptionInformationHeader: {
    flexDirection: "row",
    marginBottom: 20
  },
  subscriptionInformationHeaderTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#1E293B",
    marginLeft: 8 
  },
  subscriptionInformationContent: {
    borderRadius: 8,
    padding: 30,
    backgroundColor: "#FFFFFF80"
  },
  goBackIcon: {
    fontSize: 24, 
    color: "#0F172A",
  },
  subscriptionInformationContentHeader: {
    flexDirection: "row"
  },
  logo: {
    width: 44,
    height: 44,
    marginRight: 12
  },
  planName: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#000000"
  },
  subTitle: {
    fontFamily: "Inter",
    fontWeight: "500",
    color: "#4C7731"
  },
  checked: {
    width: 16,
    height: 16,
    marginRight: 12
  },
  benefits: {
    marginTop: 30
  },
  benefit: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8
  },
  benefitText: {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: "400",
    color: "#000"
  },
  boldText: {
    fontWeight: "700",
  },
  planAndPriceTitle: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#4C7731",
    textTransform: "uppercase",
    marginTop: 30
  },
  freeWeek: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#8861A9",
    marginTop: 30
  },
  pricePeriodText: {
    fontFamily: "Inter",
    fontSize: 20, 
    fontWeight: "400", 
    color: "#474747",
    marginTop: 30
  },
  priceText: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#000", 
    fontSize: 24
  },
  billedContainer: {
    width: "100%",
    maxWidth: 387,
    backgroundColor: "#FFFFFF80",
    padding: 30,
    borderRadius: 8
  },
  billedToText: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#64748B", 
    marginBottom: 8
  },
  field: {
    marginBottom: 16,
  },
  fieldContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16
  },
  halfField: {
    width: "48%",
  },
  label: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#475569", 
  },
  input: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
    ...(Platform.OS === "web" && {
      borderWidth: 0, 
      outlineStyle: "none"
    })
  },
  select: {
    width: "100%",
    zIndex: 999,
    height: 44,
    borderRadius: 10
  },
  button: {
    backgroundColor: "#8861A9"
  },
  buttonText: {
    fontWeight: "700",
    fontFamily: "Inter",
    fontSize: 16,
    color: "#FFFFFF"
  }
});
// Customizable Area End
