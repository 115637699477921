import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import PlanCard from "../../../components/src/PlanCard";
import ArrowLeftIcon from '@mui/icons-material/ArrowBackOutlined';
import Select from "react-select";
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={styles.screen}>
        <ScrollView>
          <View style={styles.main}>
            <View style={styles.body}>

              <View style={styles.subScreenHeader}>
                <TouchableOpacity testID="btnGoBack" {...this.btnGoBackProps}  style={styles.gobackButton}>
                  <ArrowLeftIcon sx={styles.icon} />
                </TouchableOpacity>
                <Typography variant="xl" style={styles.sectionTitle}>Billing information</Typography>
              </View>


              <View style={styles.currentPlanContainer}>
                <Typography variant="xs" style={styles.sectionTitle}>PLANS & PRICING</Typography>

                <PlanCard
                  testID="btnSubscription"
                  planName={this.state.plan.name}
                  planDescription={this.state.plan.description}
                  benefits={this.state.plan.benefits}
                  monthlyPrice={this.state.plan.monthlyPrice}
                  yearlyPrice={this.state.plan.yearlyPrice}
                  paymentTypeText={this.state.plan.paymentTypeText}
                  eventPrice={this.state.plan.eventPrice}
                  buttonActionText="Subscribe"
                  onActionButton={() => {}}
                />
             
              </View>

              <View style={styles.currentPlanContainer}>
                <Typography variant="xs" style={styles.sectionTitle}>BILLING CYCLE</Typography>

                <TouchableOpacity
                  testID="btnSelectMonthlyCycle"
                  style={this.state.billingCycle === "monthly" ? styles.paymentTypeButtonRadioSelected : styles.paymentTypeButtonRadio}
                  onPress={() => this.setState({ billingCycle: "monthly" })}
                >
                  <View style={this.state.billingCycle === "monthly" ? styles.paymentTypeRadioSelected : styles.paymentTypeRadio} />
                  <View style={styles.buttonRadioContent}>
                    <Typography variant="base" style={styles.buttonRadioTitle}>Pay monthly</Typography>
                    <Typography variant="xs" style={styles.buttonRadioSubtitle}>$25/month</Typography>
                  </View>

                </TouchableOpacity>

                <TouchableOpacity
                  testID="btnSelectYearlyCycle"
                  style={this.state.billingCycle === "yearly" ? styles.paymentTypeButtonRadioSelected : styles.paymentTypeButtonRadio}
                  onPress={() => this.setState({ billingCycle: "yearly" })}
                >
                  <View style={this.state.billingCycle === "yearly" ? styles.paymentTypeRadioSelected : styles.paymentTypeRadio} />
                  <View style={styles.buttonRadioContent}>
                    <Typography variant="base" style={styles.buttonRadioTitle}>Pay yearly</Typography>
                    <Typography variant="xs" style={styles.buttonRadioSubtitle}>$300/year</Typography>
                  </View>
                </TouchableOpacity>
              
              </View>

              <View style={styles.currentPlanContainer}>
                <Typography variant="xs" style={styles.sectionTitle}>BILLED TO</Typography>

              
                <View style={styles.formWrapper}>
                  <View style={styles.field}>
                    <Typography variant="sm" style={styles.label}>Name</Typography>
                    <Input
                      testID="txtInputUserName"
                      placeholderTextColor={"#94A3B8"}
                      placeholder="John Doe"
                      value={this.state.name}
                      {...this.txtInputNameProps} 
                    />
                  </View>

                  <View style={styles.field}>
                    <Typography variant="sm" style={styles.label}>Card number</Typography>
                    <Input
                      testID="txtInputCardNumber"
                      placeholderTextColor={"#94A3B8"}
                      value={this.state.cardNumber}
                      {...this.txtInputCardNumberProps} 
                    />
                  </View>

                  <View style={styles.fieldContainer}>
                    <View style={styles.halfField}>
                      <Typography variant="sm" style={styles.label}>Expiration Date</Typography>
                      <Input
                        testID="txtInputExpirationDate"
                        placeholderTextColor={"#94A3B8"}
                        value={this.state.expirationDate}
                        {...this.txtInputExpirationDateProps} 
                      />
                    </View>

                    <View style={styles.halfField}>
                      <Typography variant="sm" style={styles.label}>CVV</Typography>
                      <Input
                        testID="txtInputCvv"
                        placeholderTextColor={"#94A3B8"}
                        value={this.state.cvv}
                        {...this.txtInputCvvProps} 
                      />
                    </View>
                  </View>

                  <View style={styles.field}>
                    <Typography variant="sm" style={styles.label}>Country</Typography>

                    <Select
                      style={{ fontFamily: "Inter" }}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          borderRadius: 10,
                          height: 44,
                        }),
                        container: (baseStyle) => ({
                          ...baseStyle,
                          fontFamily: "Inter"
                        }),    
                      }}
                      testID="txtSelectCoumtry"
                      placeholder=""
                      value={this.state.country}
                      {...this.selectInputCountryProps}
                    />
                  </View>


                  <View style={styles.lastField}>
                    <Typography variant="sm" style={styles.label}>Zip code</Typography>
                    <Input
                      testID="txtInputZipcode"
                      placeholderTextColor={"#94A3B8"}
                      value={this.state.zipCode}
                      {...this.txtInputZipCodeProps} 
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  screen: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#9BCE7B",
  },
  main: {
    position: "relative",
    flex: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#9BCE7B",
    padding: 24,
    width: "100%",
    height: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "100%",
    maxWidth: "1100px"
  },
  subScreenHeader: {
    flexDirection: "row",
    alignItems: "center"
  },
  gobackButton: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    marginRight: 8
  },
  sectionTitle: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#64748B",
    textTransform: "uppercase",
    // marginBottom: 8
  },
  icon: {
    fontSize: 24,
    color: "#0F172A"
  },
  currentPlanContainer: {
    marginTop: 24
  },
  paymentTypeButtonRadio: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#CBD5E1",
    borderRadius: 8,
    marginTop: 8,
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  paymentTypeRadio: {
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth:  1,
    borderColor: '#2A0066',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentTypeRadioSelected: {
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth:  6,
    borderColor: '#2A0066',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentTypeButtonRadioSelected: {
    backgroundColor: "#E1CCFF",
    borderWidth: 1,
    borderColor: "#A666FF",
    borderRadius: 8,
    marginTop: 8,
    color: "#2A0066",
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  buttonRadioContent: {
    marginLeft: 20, 
    flexDirection: "column", 
    alignItems: "flex-start", 
    justifyContent: "space-between", 
    flex: 1
  },
  buttonRadioTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#000"
  },
  buttonRadioSubtitle: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#000"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: 8,
    marginBottom: 20
  },
  fieldContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24
  },
  field: {
    flex: 1, 
    marginBottom: 24,
    zIndex: 99 
  },
  halfField: {
    width: "48%",
  },
  lastField: {
    flex: 1,
  },
  label: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#334155"
  },
})



// Customizable Area End
