import React from "react";
// Customizable Area Start
import { Platform, ScrollView, StyleSheet, Switch, TouchableOpacity, View } from "react-native";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
} from "@mui/material";
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
import "@mui/styles";
const DatePicker = require("react-datepicker");
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/RemoveOutlined';
import AddIcon from '@mui/icons-material/AddOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppHeader from "../../../components/src/AppHeader.web";
import Popover from "../../../components/src/Popover.web";
import { Typography, Button, Input } from "@builder/component-library";
import UploadIcon from '@mui/icons-material/FileDownloadOutlined';
import TrashIcon from '@mui/icons-material/DeleteOutlineOutlined';
import "react-datepicker/dist/react-datepicker.css";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCropedImage = () => {
    if (this.state.imgCropSize === 0) {
      return (
        <View style={[styles.uploadPictureImageCrop, styles.imageCrop0]}>
          <img src={this.state.uploadedPictureImage} style={{...styles.uploadPictureImage,  top: -94, left: -94 }} />  
        </View>
      )
    } else if (this.state.imgCropSize === 25) {
      return (
        <View style={[styles.uploadPictureImageCrop, styles.imageCrop25]}>
          <img src={this.state.uploadedPictureImage} style={{...styles.uploadPictureImage,  top: -70.5, left: -70.5 }} />  
        </View>
      )
    } else if (this.state.imgCropSize === 50) {
      return (
        <View style={[styles.uploadPictureImageCrop, styles.imageCrop50]}>
          <img src={this.state.uploadedPictureImage} style={{...styles.uploadPictureImage,  top: -47, left: -47 }} />  
        </View>
      )
    } else if (this.state.imgCropSize === 75) {
      return (
        <View style={[styles.uploadPictureImageCrop, styles.imageCrop75]}>
          <img src={this.state.uploadedPictureImage} style={{...styles.uploadPictureImage,  top: -23.5, left: -23.5 }} />  
        </View>
      )
    } else {
      return (
        <View style={[styles.uploadPictureImageCrop, styles.imageCrop100]}>
          <img src={this.state.uploadedPictureImage} style={styles.uploadPictureImage} />  
        </View>
      )
    }
  }
  // Customizable Area End
  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

  render() {
    // Customizable Area Start
    return (
      <div style={styles.screen}>

        <Popover 
          testID="popover"
          icon={this.state.popoverState.icon}
          iconColor={this.state.popoverState.iconColor}
          title={this.state.popoverState.title}
          subTitle={this.state.popoverState.subTitle}
          primaryButtonText={this.state.popoverState.primaryButtonText}
          primaryButtonColor={this.state.popoverState.primaryButtonColor}
          secondaryButtonText={this.state.popoverState.secondaryButtonText}
          visible={this.state.popoverState.visibility}
          {...this.popoverProps}
        />

        <AppHeader {...this.props} />

        {
          this.state.uploadPictureVisible  && (
            <View style={styles.uploadPictureOverlay}>
              <View style={styles.uploadPictureContainer}>
                <View style={styles.uploadPictureHeader}>
                  <Typography variant="xl" style={styles.uploadPictureHeaderText}>Upload profile</Typography>
                  <TouchableOpacity testID="btnCloseUploadPictureModal" onPress={() => this.setState({ uploadPictureVisible: false })}>
                    <CloseIcon sx={styles.uploadPictureHeaderIcon} />
                  </TouchableOpacity>
                </View>

                <View style={styles.uploadPictureImageContainer}>
                  <img src={this.state.uploadedPictureImage} style={styles.uploadPictureImageOverlay} />  
                  {this.renderCropedImage()}
                </View>

                <View style={styles.uploadPictureCropActions}>
                  <TouchableOpacity testID="btnDecrementCrop" onPress={() => this.handleChangeCropSize("decrement")}>
                    <RemoveIcon sx={styles.uploadPictureCropActionsIcon} />
                  </TouchableOpacity>

                  <Typography variant="sm" style={styles.uploadPictureCropActionsText}>{this.state.imgCropSize.toString()}%</Typography>

                  <TouchableOpacity testID="btnIncrementCrop" onPress={() => this.handleChangeCropSize("increment")}>
                    <AddIcon sx={styles.uploadPictureCropActionsIcon} />
                  </TouchableOpacity>
                </View>
                
                <View style={styles.uploadPictureCropFooter}>
                  <Button
                    style={{ width: 327, backgroundColor: "#8861A9" }}
                    textStyle={{ color: "#FFF" }}
                    text="Done"
                    // onPress={this.props.primaryButtonAction}
                  />  
                  <Button
                    style={{ backgroundColor: "transparent", marginTop: 12 }}
                    textStyle={{ color: "#8861A9" }}
                    text="Upload another"
                    onPress={this.handleOpenSelectProfilePicture}
                  />
                </View>
              </View>
            </View>
          )
        }

        <ScrollView style={styles.scrollView}>
          <View style={styles.main}>      
            <Typography variant="xl" style={styles.label}>User Profile</Typography>

            <View style={styles.header}>
              <img src={this.state.profilePicture} style={styles.profileImage} />
              <View style={styles.editPictureContainer}>
                <TouchableOpacity testID="btnEditProfilePicture" style={styles.editPictureButton} onPress={() => this.setState({ dropdownMenuVisible: !this.state.dropdownMenuVisible })}>
                  <Typography variant="xs" style={styles.editPictureButtonText}>Edit Profile picture</Typography>
                </TouchableOpacity>

                <View style={this.state.dropdownMenuVisible ? styles.dropdownMenuVisible : styles.dropdownMenu}>
                  <View style={styles.dropdownMenuHeader}>
                    <Typography variant="xs" style={styles.dropdownMenuHeaderText}>Profile picture</Typography>
                    <TouchableOpacity testID="btnCloseDropdonw" onPress={() => this.setState({ dropdownMenuVisible: false })}>
                      <CloseIcon sx={styles.dropdownMenuHeaderIcon} />
                    </TouchableOpacity>
                  </View>

                  <input 
                    id="inputFile"
                    type="file"
                    ref={this.inputProfilePictureRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={this.handleSelectedFile}
                  />

                  <TouchableOpacity testID="btnUploadPicture" style={styles.dropdownMenuButton} onPress={this.handleOpenSelectProfilePicture}>
                    <UploadIcon sx={styles.dropdownMenuButtonIcon} />
                    <Typography variant="xs" style={styles.dropdownMenuButtonText}>Upload</Typography>
                  </TouchableOpacity>

                  <View style={styles.dropdownDivider} />

                  <TouchableOpacity testID="btnRemovePicture" style={styles.dropdownMenuButton}>
                    <TrashIcon sx={styles.dropdownMenuButtonIcon} />
                    <Typography variant="xs" style={styles.dropdownMenuButtonText}>Remove picture</Typography>
                  </TouchableOpacity>
                </View>
              </View>
            </View>

            <View style={styles.sectionContainer}>
              <View style={styles.sectionHeader}>
                <Typography variant="sm" style={styles.sectionTitle}>account information</Typography>

                <TouchableOpacity testID="btnEditProfile" onPress={this.handleOpenEditProfile}>
                  <Typography variant="base" style={styles.editButtonText}>Edit</Typography>
                </TouchableOpacity>
              </View>

              <View style={styles.fieldContaienr}>
                <Typography variant="sm" style={styles.fieldDescription}>Name</Typography>
                <Typography variant="xs" style={styles.fieldValue}>: {this.state.fullName}</Typography>
              </View>

              <View style={styles.fieldContaienr}>
                <Typography variant="sm" style={styles.fieldDescription}>Email</Typography>
                <Typography variant="xs" style={styles.fieldValue}>: {this.state.email}</Typography>
              </View>

              <View style={styles.fieldContaienr}>
                <Typography variant="sm" style={styles.fieldDescription}>Zip code</Typography>
                <Typography variant="xs" style={styles.fieldValue}>: {this.state.zipCode}</Typography>
              </View>

              <View style={styles.fieldContaienr}>
                <Typography variant="sm" style={styles.fieldDescription}>Password</Typography>
                <TouchableOpacity testID="btnGoChangePassword" onPress={this.goToChangePassword}>
                  <Typography variant="xs" style={styles.changePasswordButtoText}>Change password</Typography>
                </TouchableOpacity>
              </View>
            </View>

            <View style={styles.sectionContainer}>
              <View style={styles.sectionHeader}>
                <Typography variant="base" style={styles.sectionTitle}>Notification preferences</Typography>
              </View>

              <View style={styles.notitficationPreferencesContaienr}>
                <View style={styles.swithcContainer}>
                  <Typography variant="sm" style={styles.label}>In-app notifications</Typography>
                  <Switch 
                    testID="switchInputInAppNotification"
                    trackColor={{
                      true: "#8861A9",
                      false: "#E2E8F0"
                    }}
                    thumbColor="#FFFFFF"
                    {...Platform.select({web: {
                      activeThumbColor: "white"
                    }})}
                    value={this.state.inAppNotifications}
                    {...this.switchInputInAppNotificationProps} 
                  />
                </View>

                <View style={styles.swithcContainer}>
                  <Typography variant="sm" style={styles.label}>Email notifications</Typography>
                  <Switch 
                    testID="switchInputEmailNotification"
                    trackColor={{
                      true: "#8861A9",
                      false: "#E2E8F0"
                    }}
                    thumbColor="#FFFFFF"
                    {...Platform.select({web: {
                      activeThumbColor: "white"
                    }})}
                    value={this.state.emailNotifications}
                    {...this.switchInputEmailNotificationProps} 
                  />
                </View>
              </View>

              <View style={styles.footerContainer}>
                <TouchableOpacity onPress={() => this.handleLogoutAccount()} testID="btnLogOut" style={styles.footerButton}>
                  <LogoutIcon sx={styles.footerButtonIcon} />
                  <Typography variant="base" style={styles.footerButtonText}>Log out</Typography>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => this.handleDeleteAccount()} testID="btnDeleteAccount" style={styles.footerButton}>
                  <DeleteIcon sx={styles.footerButtonIcon} />
                  <Typography variant="base" style={styles.footerButtonText}>Delete account</Typography>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
        {
          this.state.sideSectionVisible && (
            <View style={styles.overlayScreen}>
              <View style={styles.sideSection}>
                <View style={styles.sideSectionHeader}>
                  <TouchableOpacity onPress={this.handleCloseSideSection} style={styles.sideSectionHeaderGoBackButton}>
                    <ArrowBackIcon style={styles.sideSectionHeaderGoBackIcon} />
                  </TouchableOpacity>

                  <Typography variant="xl" style={styles.sideSectionHeaderText}>Basic information</Typography>
                </View>

                <View style={styles.sideSectionForm}>
                  <View style={styles.sideSectionField}>
                    <Typography variant="sm" style={styles.sideSectionLabel}>Name</Typography>
                    <Input 
                      testID="txtInputName"
                      style={styles.sideSectionInput}
                      value={this.state.fullName}
                      {...this.txtInputNameProps}
                    />
                  </View>

                  <View style={styles.sideSectionField}>
                    <Typography variant="sm" style={styles.sideSectionLabel}>Email</Typography>
                    <Input 
                      testID="txtInputEmail"
                      style={styles.sideSectionInput}
                      value={this.state.email}
                      {...this.txtInputEmailProps}
                    />
                  </View>

                  <View style={styles.sideSectionField}>
                    <Typography variant="sm" style={styles.sideSectionLabel}>Zip Code</Typography>
                    <Input 
                      testID="txtInputZipCode"
                      style={styles.sideSectionInput}
                      value={this.state.zipCode}
                      {...this.txtInputZipCodeProps}
                    />
                  </View>

                  <Button 
                    testID="btnSaveChanges"
                    textStyle={styles.sideSectionButtonText}
                    style={styles.sideSectionButton}
                    text={this.state.loading ? "" : "Save changes"}
                    onPress={this.updateCustomizableProfile}
                    loading={this.state.loading}
                  />
                </View>
              </View>
            </View>
          )
        }
      </div>    
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  overlayScreen: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 11,
  },
  sideSection: {
    width: "100%",
    height: "100vh",
    maxWidth: 375,
    paddingHorizontal: 24,
    paddingTop: 45,
    marginLeft: "auto",
    backgroundColor: "#9BCE7B",
    zIndex: 12,
    position: "relative",
  },
  sideSectionHeader: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center"
  },
  sideSectionHeaderGoBackButton: {
    width: 32,
    height: 32,
    borderRadius: 100,
    backgroundColor: "#FFF",
    alignItems: "center",
    justifyContent: "center"
  },
  sideSectionHeaderGoBackIcon: {
    fontSize:  24,
    color: "#0F172A"
  },
  sideSectionHeaderText: {
    fontWeight: "700",
    color: "#1E293B",
    marginLeft: 8
  },
  sideSectionForm: {
    marginTop: 32
  },
  sideSectionField: {
    marginBottom: 24
  },
  sideSectionLabel: {
    fontWeight: "700",
    color: "#334155",
    marginBottom: 4
  },
  sideSectionInput: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
    ...(Platform.OS === "web" && {
      borderWidth: 0, 
      outlineStyle: "none"
    })
  },
  sideSectionButton: {
    backgroundColor: "#8861A9"
  },
  sideSectionButtonText: {
    color: "#FFFFFF",
    fontSize: 16
  },
  screen: {
    width: "100%", 
    height: "100%", 
    backgroundColor: "#9BCE7B",
    flex: 1,
  },
  scrollView: {
    backgroundColor: "#9BCE7B",
  },
  main: {
    position: "relative",
    flex: 1, 
    display: "flex", 
    marginHorizontal: "auto",
    marginTop: 48,
    width: "100%", 
    height: "100%", 
    maxWidth: 872,
    backgroundColor: "#9BCE7B",
  },
  uploadPictureOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  uploadPictureContainer: {
    width: "100%",
    maxWidth: 669,
    borderRadius: 16,
    padding: 14,
    paddingHorizontal: 24,
    backgroundColor: "#9BCE7B",
    zIndex: 2,
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  uploadPictureHeader: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  uploadPictureHeaderText: {
    fontWeight: "700",
    color: "#1E293B"
  },
  uploadPictureHeaderIcon: {
    color: "#0F172A",
    fontSize: 24
  },
  uploadPictureImageContainer: {
    marginTop: 50,
    width: 376,
    height: 376,
    borderRadius: 12,
    position: "relative",
    backgroundColor: "#08080873"
  },
  uploadPictureImage: {
    position: 'absolute',
    width: 376,
    height: 376,
    resizeMode: "cover",
    borderRadius: 12,
  },
  uploadPictureImageOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.3,
    borderRadius: 12,
    alignItems: "center",
    justifyContent: "center"
  },
  uploadPictureImageCrop: {
    position: "absolute", 
    top: "50%",
    left: "50%",
    overflow: 'hidden',
    // borderWidth: 2,
    // borderStyle: "solid",
    // borderColor: "#08080873"
  },
  imageCrop0: {
    width: 188,
    height: 188,
    borderRadius: 94,
    transform: [{ translateX: -94 }, { translateY: -94 }],
  },
  imageCrop25: {
    width: 235,
    height: 235,
    borderRadius: 117,
    transform: [{ translateX: -117 }, { translateY: -117 }],
  },
  imageCrop50: {
    width: 282,
    height: 282,
    borderRadius: 141,
    transform: [{ translateX: -141 }, { translateY: -141 }],
  },
  imageCrop75: {
    width: 329,
    height: 329,
    borderRadius: 164,
    transform: [{ translateX: -164 }, { translateY: -164 }],
  },
  imageCrop100: {
    width: 376,
    height: 376,
    borderRadius: 188,
    transform: [{ translateX: -188 }, { translateY: -188 }],
  },
  uploadPictureCropActions: {
    flexDirection: "row",
    marginTop: 24,
    alignItems: "center"
  },
  uploadPictureCropActionsText: {
    fontWeight: "700",
    color: "#000000",
    backgroundColor: "#E2E8F0",
    borderRadius: 8,
    padding: 8,
    marginLeft: 16,
    marginRight: 16
  },
  uploadPictureCropActionsIcon: {
    fontSize: 32,
    color: "#0F172A"
  },
  uploadPictureCropFooter: {
    marginVertical: 24
  },
  alertContainer: {
    paddingVertical: 21, 
    paddingHorizontal: 16, 
    width: "100%", 
    display: "flex", 
    alignItems: "flex-start", 
    backgroundColor: "#FEE2E2", 
    borderRadius: 4, 
    borderLeftWidth: 4, 
    borderLeftColor: "#DC2626", 
    marginBottom: 48
  },
  alertText: {
    fontFamily: "Inter",
    color: "#DC2626", 
    fontSize: 12
  },
  label: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#334155"
  },
  header: {
    flexDirection: "row",
    marginTop: 24
  },
  profileImage: {
    width: 156,
    height: 156,
    borderRadius: 136,
    borderWidth: 4,
    borderColor: "#F0E5FF",
    borderStyle: "solid",
    resizeMode: "contain",
  },
  editPictureContainer: {
    marginLeft: 38,
    marginTop: 40
  },
  editPictureButton: {
  },
  editPictureButtonText: {
    fontFamily: "Inter",
    fontWeight: "500",
    color: "#5F05FF"
  },
  editButtonText: {
    fontFamily: "Inter",
    fontWeight: "500",
    color: "#8861A9"
  },
  fieldContaienr: {
    flexDirection: "row",
    marginTop: 20
  },
  dropdownMenu: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#F5F5F5",
    borderStyle: "solid",
    borderRadius: 8,
    marginTop: 12,
    opacity: 0,
    ...(Platform.OS === 'web' && {
      transition: 'opacity 0.3s ease',
    }),
  },
  dropdownMenuVisible: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#F5F5F5",
    borderStyle: "solid",
    borderRadius: 8,
    marginTop: 12,
    opacity: 1,
    ...(Platform.OS === 'web' && {
      transition: 'opacity 0.3s ease',
    }),
  },
  dropdownMenuHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 2,
    paddingHorizontal: 8
  },
  dropdownMenuHeaderText: {
    fontWeight: "700",
    color: "#0F172A"
  },
  dropdownMenuHeaderIcon: {
    color: "#0F172A",
    fontSize: 12
  },
  dropdownMenuButton: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  dropdownDivider: {
    width: "100%",
    height: 1,
    backgroundColor: "#DFDFDF"
  },
  dropdownMenuButtonText: {
    fontWeight: "400",
    color: "#3C3E49",
    marginLeft: 8
  },
  dropdownMenuButtonIcon: {
    fontSize: 16,
    color: "#000000"
  },
  fieldDescription: {
    width: 140,
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#334155"
  },
  fieldValue: {
    fontFamily: "Inter",
    fontWeight: "400",
    color: "#334155"
  },
  changePasswordButtoText: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#8861A9"
  },

  sectionTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#64748B", 
    textTransform: "uppercase"
  },
  sectionContainer: {
    marginTop: 30,
    zIndex: 999
  },
  sectionHeader: {
    borderBottomColor: "#E2E8F0", 
    borderBottomWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  notitficationPreferencesContaienr: {
    marginTop: 20,
    marginBottom: 24,
    maxWidth: 375,
    width: "100%"
  },
  swithcContainer: {
    flex: 1, 
    marginBottom: 24, 
    flexDirection: 'row', 
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerContainer: {
    flexDirection: "row"
  },
  footerButton: {
    flexDirection: "row",
    paddingVertical: 16,
    paddingHorizontal: 30
  },
  footerButtonIcon: {
    fontSize: 24, 
    color: "#8861A9",
  },
  footerButtonText: {
    fontFamily: "Inter",
    fontWeight: "400",
    color: "#8861A9",
    marginLeft: 8
  },
})
// Customizable Area End
