import React from "react";
// Customizable Area Start
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppHeader from "../../../components/src/AppHeader.web";
import PopoverChangePassword from "../../../components/src/Popover.web";
import { Typography, Button, Input } from "@builder/component-library";
import "react-datepicker/dist/react-datepicker.css";
// Customizable Area End

import ChangePasswordController, {
  Props,
} from "./ChangePasswordController";
// Customizable Area End

export default class ChangePassword extends ChangePasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={styles.screen}>
        <PopoverChangePassword 
          testID="popover"
          icon={this.state.popoverState.icon}
          iconColor={this.state.popoverState.iconColor}
          title={this.state.popoverState.title}
          subTitle={this.state.popoverState.subTitle}
          primaryButtonText={this.state.popoverState.primaryButtonText}
          primaryButtonColor={this.state.popoverState.primaryButtonColor}
          secondaryButtonText={this.state.popoverState.secondaryButtonText}
          visible={this.state.popoverState.visibility}
          {...this.popoverProps}
        />

        <AppHeader {...this.props} />

        <ScrollView>
          <View style={styles.main}>  
            {
              this.state.alertVisibility && (
                <View style={styles.alertContainer}>
                  <Typography style={styles.alertText}>{this.state.alertMessage}</Typography>
                </View>
              )
            }    

            <View style={styles.header}>
              <TouchableOpacity testID="btnGoBack" {...this.btnGoBack} style={styles.goBackButton}>
                <ArrowBackIcon style={styles.goBackButtonIcon} />
              </TouchableOpacity>

              <Typography variant="xl" style={styles.headerText}>Change password</Typography>
            </View>

            <View style={styles.formWrapper}>
              <View style={styles.field}>
                <Typography variant="sm" style={styles.label}>Old password</Typography>
                <Input 
                  testID="txtInputOldPassword"
                  style={styles.input}
                  secureTextEntry={true}
                  value={this.state.oldPassword}
                  {...this.txtInputOldPasswordProps}
                />
              </View>

              <View style={styles.field}>
                <Typography variant="sm" style={styles.label}>New password</Typography>
                <Input 
                  testID="txtInputNewPassword"
                  style={styles.input}
                  secureTextEntry={true}
                  value={this.state.newPassword}
                  {...this.txtInputNewPasswordProps}
                />
              </View>

              <View style={styles.field}>
                <Typography variant="sm" style={styles.label}>Confirm new password</Typography>
                <Input 
                  testID="txtInputConfirmNewPassword"
                  style={styles.input}
                  secureTextEntry={true}
                  value={this.state.confirmNewPassword}
                  {...this.txtInputConfirmNewPasswordProps}
                />
              </View>

              <Button 
                testID="btnChangePassword"
                textStyle={styles.buttonText}
                style={styles.button}
                loading={this.state.loading}
                text={this.state.loading ? "" : "Change password"}
                onPress={this.changePassword}
              />
            </View>
          </View>
        </ScrollView>
      </div>
    ); // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screen: {
    width: "100%", 
    height: "100%", 
    backgroundColor: "#9BCE7B",
    flex: 1,
  },
  main: {
    position: "relative",
    flex: 1, 
    display: "flex", 
    marginHorizontal: "auto",
    marginTop: 110,
    width: "100%", 
    height: "100%", 
    maxWidth: 375,
  },
  alertContainer: {
    paddingVertical: 21, 
    paddingHorizontal: 16, 
    width: "100%", 
    display: "flex", 
    alignItems: "flex-start", 
    backgroundColor: "#FEE2E2", 
    borderRadius: 4, 
    borderLeftWidth: 4, 
    borderLeftColor: "#DC2626", 
    marginBottom: 48
  },
  alertText: {
    fontFamily: "Inter",
    color: "#DC2626", 
    fontSize: 12
  },
  header: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center"
  },
  goBackButton: {
    width: 32,
    height: 32,
    borderRadius: 100,
    backgroundColor: "#FFF",
    alignItems: "center",
    justifyContent: "center"
  },
  goBackButtonIcon: {
    fontSize:  24,
    color: "#0F172A"
  },
  headerText: {
    fontWeight: "700",
    color: "#1E293B",
    marginLeft: 8
  },
  formWrapper: {
    marginTop: 32,
    paddingHorizontal: 24
  },
  field: {
    marginBottom: 24
  },
  label: {
    fontWeight: "700",
    color: "#334155",
    marginBottom: 4
  },
  button: {
    marginTop: 12,
    backgroundColor: "#8861A9"
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 16
  },
  input: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
    ...Platform.select({
      web: {
        borderWidth: 0, 
        outlineStyle: "none"
      },
      default: {}
    })
  }
})
// Customizable Area End
