import React from "react";

// Customizable Area Start
import {
    View,
    StyleSheet,
    ScrollView,
    Platform,
} from "react-native";
import { Input, Button, Typography } from '@builder/component-library';
import { imgVisbility, imgVisbilityOff, imgCorrect, imgWrong } from "./assets";

// Customizable Area End

import PasswordController, { Props } from "./PasswordController";

export default class NewPassword extends PasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <div style={styles.screen}>
        <ScrollView>
          <View style={styles.main}>
            <View style={styles.body}>
              <Typography variant="lg" style={styles.title}>New password</Typography>
              <Typography variant="base" style={styles.subTitle}>Enter a new password for</Typography>
              <Typography variant="base" style={styles.subTitle}>{this.state.email}</Typography>

              <View style={styles.formContainer}>
                {
                  this.state.alertVisibility && (
                    <View style={styles.alertContainer}>
                      <Typography style={styles.alertText}>{this.state.alertMessage}</Typography>
                    </View>
                  )
                }
                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>New Password</Typography>
                  <Input
                    style={styles.input}
                    testID="txtInputNewPassword"
                    placeholder="Your new password"
                    placeholderTextColor={"#94A3B8"}
                    value={this.state.newPassword}
                    {...this.txtInputNewPasswordProps} 
                    secureTextEntry={this.state.enableNewPasswordField}
                    rightIcon={
                      <Button
                        testID="btnNewPasswordShowHide"
                        aria-label="toggle password visibility"
                        {...this.btnNewPasswordShowHideProps} 
                        style={[styles.removeBackground]}
                        icon={!this.state.enableNewPasswordField ? (
                          <img src={imgVisbility} />
                        ) : (
                          <img src={imgVisbilityOff} />
                        )}
                      />
                    }
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Confirm new password</Typography>
                  <Input
                    style={styles.input}
                    testID="txtInputConfirmNewPassword"
                    placeholder="Password"
                    placeholderTextColor={"#94A3B8"}
                    value={this.state.confirmNewPassword}
                    {...this.txtInputConfirmNewPasswordProps} 
                    secureTextEntry={this.state.enableConfirmNewPasswordField}
                    rightIcon={
                      <Button
                        testID="btnConfirmNewPasswordShowHide"
                        aria-label="toggle password visibility"
                        {...this.btnConfirmNewPasswordShowHideProps} 
                        style={[styles.removeBackground]}
                        icon={!this.state.enableConfirmNewPasswordField ? (
                          <img src={imgVisbility} />
                        ) : (
                          <img src={imgVisbilityOff} />
                        )}
                      />
                    }
                  />
                </View>

                <View style={styles.validationContainer}>
                  <Typography variant="base" style={styles.validationTitle}>Your password must contain</Typography>

                  <View style={styles.validationWraper}>
                    <img src={this.state.passwordValidations.uppercase ? imgCorrect : imgWrong} style={styles.validationImage} />
                    <Typography variant="xs" style={styles.validationText}>{this.errorPasswordUppercase}</Typography>
                  </View>

                  <View style={styles.validationWraper}>
                    <img src={this.state.passwordValidations.lowercase ? imgCorrect : imgWrong} style={styles.validationImage} />
                    <Typography variant="xs" style={styles.validationText}>{this.errorPasswordLowercase}</Typography>
                  </View>

                  <View style={styles.validationWraper}>
                    <img src={this.state.passwordValidations.number ? imgCorrect : imgWrong} style={styles.validationImage} />
                    <Typography variant="xs" style={styles.validationText}>{this.errorPasswordNumber}</Typography>
                  </View>

                  <View style={styles.validationWraper}>
                    <img src={this.state.passwordValidations.length ? imgCorrect : imgWrong} style={styles.validationImage} />
                    <Typography variant="xs" style={styles.validationText}>{this.errorPasswordLength}</Typography>
                  </View>
                </View>

                <View
                  style={styles.buttonContanier}
                >
                  <Button
                    testID="btnSetNewPassword"
                    textStyle={styles.buttonText}
                    style={styles.button}
                    {...this.btnSetNewPasswordProps}
                    loading={this.state.loading}
                    text={this.state.loading ? "" : "Set new password"}
                  />
                </View>
                <View style={styles.logInButtonContainer}>
                  <Typography variant="base" style={styles.logInButtonText} component="span">
                    Back to
                  </Typography>
                  <Button
                    testID={"btnLogIn"}
                    style={styles.removeBackground}
                    textStyle={styles.logInButtonTextHighlighted}
                    onPress={() => this.goToLogin()}
                    text="Log In"
                  />
                </View>  
              </View>
            </View>
          </View>
        </ScrollView>
      </div>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screen: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#9BCE7B"
  },
  main: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    padding: 24,
    width: "100%",
    height: "100%",
    backgroundColor: "#9BCE7B"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: 360,
    marginTop: 160,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    marginTop: 48,
  },
  alertContainer: {
    paddingVertical: 21,
    paddingHorizontal: 16,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#FEE2E2",
    borderRadius: 4,
    borderLeftWidth: 4,
    borderLeftColor: "#DC2626",
    marginBottom: 48
  },
  alertText: {
    fontFamily: "Inter",
    color: "#DC2626",
    fontSize: 12
  },
  title: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#0F172A",
    marginBottom: 8,
    textAlign: "center",
  },
  subTitle: {
    fontFamily: "Inter",
    fontWeight: "400",
    color: "#0F172A",
    textAlign: "center"
  },
  validationTitle: {
    fontFamily: "Inter",
    fontWeight: "400",
    color: "#0F172A",
    marginBottom: 8
  },
  field: {
    width: "100%",
    marginBottom: 24
  },
  label: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#334155"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  validationContainer : {
    marginTop: 24,
    width: "100%",
  },
  validationWraper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8
  },
  validationImage: {
    marginRight: 4,
    width: 12,
    height: 12
  },
  validationText: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#000"
  },
  buttonContanier: {
    flex: 1,
    width: "100%"
  },
  button: {
    backgroundColor: "#8861A9", 
    marginTop: "48px"
  },
  buttonText: {
    color: "#FFFFFF"
  },
  logInButtonContainer: {
    display: "flex", 
    flexDirection: "row", 
    alignItems: "center", 
    justifyContent: "center", 
    marginTop: 24
  },
  logInButtonText: {
    fontFamily: "Inter", 
    color: "#0F172A"
  },
  logInButtonTextHighlighted: {
    fontFamily: "Inter", 
    fontSize: 16,
    fontWeight: "700", 
    color: "#8861A9"
  },
  input: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
    ...Platform.select({
      web: {
        borderWidth: 0, 
        outlineStyle: "none"
      },
      default: {}
    })
  }
});
// Customizable Area End
